var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SidebarLeft',{class:{ 'sidebar-left-show': _vm.$store.state.sidebars.leftSidebar }},[(_vm.isLeftSidebarVisible)?_c('FilterDirectoryForm',{ref:"filter",on:{"selected-name":_vm.selectedNameDirectory}}):_vm._e()],1),(_vm.directory)?_c('TableContainer',{ref:"table-container",attrs:{"columns":_vm.tableData,"counter-filter":_vm.searchCount,"is-archive":false,"is-archiving":false,"is-loading-items":_vm.$store.state.other.isLoadingItems,"is-loading-new-item":_vm.$store.state.other.isLoadingNewItem,"rows":_vm.directories,"show-add-button":_vm.$can('edit', `${_vm.directory.accessName}-create`),"sorting-fields":_vm.sortingFields,"title":_vm.directory.title,"name":"directory","is-filter":false},on:{"show-right-sidebar":_vm.showRightSidebar,"on-selected":_vm.selectItem,"delete-item":_vm.deleteItem,"update-data":_vm.updateData}},[_c('template',{slot:"head-buttons"},[(!_vm.$store.state.sidebars.leftSidebar)?_c('zem-link',{staticClass:"zem-link-mobile tw-gap-x-1",on:{"click":() => {
            _vm.$store.commit('sidebars/changeLeftSidebar', true);
            _vm.isLeftSidebarVisible = true;
          }}},[_c('database-icon',{staticClass:"primary-icon",attrs:{"size":"14"}}),_c('span',{staticClass:"mobile-d-none"},[_vm._v("Список")])],1):_vm._e(),(
          _vm.selectedDirectories.length > 0 &&
          _vm.selectedDirectories.length < 2 &&
          _vm.$can('edit', `${_vm.directory.accessName}-delete`)
        )?_c('zem-link',{staticClass:"zem-link-mobile tw-gap-x-1",on:{"click":_vm.deleteItem}},[_c('img',{staticClass:"tw-size-[18px] mobile-d-none",attrs:{"src":require('@/assets/icons/basket.svg'),"alt":""}}),_c('img',{staticClass:"tw-size-[18px] mobile-d",attrs:{"src":require('@/assets/icons/basket-white.svg'),"alt":""}}),_c('span',{staticClass:"mobile-d-none"},[_vm._v(_vm._s(_vm.$t("Delete")))])]):_vm._e()],1)],2):_vm._e(),(!_vm.directory)?_c('div',{staticClass:"tw-flex tw-justify-end tw-p-3 md:tw-hidden"},[_c('zem-link',{staticClass:"zem-link-mobile tw-gap-x-1",on:{"click":() => {
          _vm.$store.commit('sidebars/changeLeftSidebar', true);
          _vm.isLeftSidebarVisible = true;
        }}},[_c('database-icon',{staticClass:"primary-icon",attrs:{"size":"14"}}),_c('span',[_vm._v("Список")])],1)],1):_vm._e(),_c('SidebarRight',{class:{ 'sidebar-right--show': _vm.$store.state.sidebars.rightSidebar }},[_c('DirectoryDetails',{attrs:{"current-item":_vm.currentItem,"includes-array":_vm.includesArray,"is-edit":_vm.$can('edit', `${_vm.directory.accessName}-update`),"new-directory":_vm.newDirectory,"title-directory":_vm.directory.title},on:{"update-data":_vm.updateData,"on-new-close":function($event){_vm.newDirectory = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }