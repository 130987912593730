import ability from '@/libs/acl/ability'

export const directories = [
  {
    title: 'Бренды',
    name: 'brands',
    path: 'directory/brands',
    removePath: '',
    multiDelete: false,
    accessName: 'brand',
    includes: 'brand',
    sortingFields: [0, 1],
    fields: [
      {
        label: 'Наименование',
        field: 'title',
        sortField: 'title',
        showInTable: true,
        sort: 0,
        options: [],
      },
      {
        label: 'Url',
        field: 'url',
        sortField: 'url',
        showInTable: true,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Типы цен',
    name: 'price-types',
    path: 'directory/price-types',
    removePath: '',
    multiDelete: false,
    accessName: 'price-type',
    includes: 'brand',
    sortingFields: [0, 1],
    fields: [
      {
        label: 'Наименование',
        field: 'title',
        sortField: 'title',
        showInTable: true,
        sort: 0,
        options: [],
      },
      {
        label: 'Описание',
        field: 'description',
        sortField: 'description',
        showInTable: true,
        sort: 0,
        options: [],
      },
      {
        label: 'Бренд',
        field: 'brand',
        sortField: 'brand',
        showInTable: true,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Должности людей',
    name: 'human positions',
    path: 'human-positions',
    removePath: '',
    multiDelete: false,
    accessName: 'human-position',
    includes: '',
    sortingFields: [0],
    fields: [
      {
        label: 'Наименование',
        field: 'title',
        sortField: 'title',
        showInTable: true,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Типы организаций',
    name: 'organization type ownerships',
    path: 'organization-type-ownerships',
    removePath: '',
    multiDelete: false,
    accessName: 'organization-type-ownership',
    includes: '',
    sortingFields: [0],
    fields: [
      {
        label: 'Наименование',
        field: 'title',
        sortField: 'title',
        showInTable: true,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Участники отношений',
    name: 'community statuses',
    path: 'community-statuses',
    removePath: '',
    multiDelete: false,
    accessName: 'community-status',
    includes: '',
    sortingFields: [0],
    fields: [
      {
        label: 'Наименование',
        field: 'title',
        sortField: 'title',
        showInTable: true,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Единицы измерений',
    name: 'units',
    path: 'units',
    removePath: '',
    multiDelete: false,
    accessName: 'unit',
    includes: '',
    sortingFields: [],
    fields: [
      {
        label: 'Наименование',
        field: 'name',
        sortField: 'name',
        showInTable: true,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Характеристики продуктов',
    name: 'properties',
    path: 'directory/properties',
    removePath: '',
    multiDelete: true,
    accessName: 'directory-property',
    includes: '',
    sortingFields: [0, 1, 2],
    fields: [
      {
        label: 'Уникальный псевдоним',
        field: 'slug',
        sortField: 'slug',
        showInTable: false,
        sort: 0,
        options: [],
      },
      {
        label: 'Наименование',
        field: 'name',
        sortField: 'name',
        showInTable: true,
        sort: 0,
        options: [],
      },
      {
        label: 'Тип',
        field: 'type',
        sortField: 'type',
        showInTable: true,
        sort: 0,
        options: [],
      },
      {
        label: 'Параметры',
        field: 'params',
        sortField: 'params',
        showInTable: false,
        sort: 0,
        options: [],
      },
      {
        label: 'Единицы измерения',
        field: 'property_unit_list',
        sortField: 'property_unit_list',
        type: 'list-objects',
        showInTable: false,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Динамические характеристики',
    name: 'dynamic properties',
    path: 'calculator/dynamic-properties',
    removePath: '',
    multiDelete: false,
    accessName: 'dynamic-property',
    includes: '',
    sortingFields: [0],
    fields: [
      {
        label: 'Наименование',
        field: 'name',
        sortField: 'name',
        showInTable: true,
        sort: 0,
        options: [],
      },
      {
        label: 'Единицы измерения',
        field: 'unit',
        sortField: 'unit',
        type: 'list-objects',
        showInTable: false,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Страны',
    name: 'countries',
    path: 'countries',
    removePath: '',
    multiDelete: false,
    accessName: 'country',
    includes: '',
    sortingFields: [0],
    fields: [
      {
        label: 'Наименование',
        field: 'name',
        sortField: 'name',
        showInTable: true,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Регионы',
    name: 'regions',
    path: 'regions',
    removePath: '',
    multiDelete: false,
    accessName: 'region',
    includes: 'country,params_schema',
    sortingFields: [0],
    fields: [
      {
        label: 'Наименование',
        field: 'name',
        sortField: 'name',
        showInTable: true,
        sort: 0,
        options: [],
      },
      {
        label: 'Страна',
        field: 'country',
        sortField: 'country_id',
        showInTable: true,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Города',
    name: 'cities',
    path: 'cities',
    removePath: '',
    multiDelete: false,
    accessName: 'city',
    includes: 'region',
    sortingFields: [0],
    fields: [
      {
        label: 'Наименование',
        field: 'name',
        sortField: 'name',
        showInTable: true,
        sort: 0,
        options: [],
      },
      {
        label: 'Регион',
        field: 'region',
        sortField: 'region_id',
        showInTable: true,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Статусы заказов',
    name: 'order-statuses',
    path: 'order/statuses',
    removePath: '',
    multiDelete: false,
    accessName: 'order-status',
    includes: 'pipeline',
    sortingFields: [0],
    fields: [
      {
        label: 'Наименование',
        field: 'name',
        sortField: 'name',
        showInTable: true,
        sort: 0,
        options: [],
      },
      {
        label: 'Воронка',
        field: 'pipeline.data.name',
        sortField: 'pipeline_id',
        showInTable: true,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Статусы партнеров',
    name: 'partner-statuses',
    path: 'order/partner-statuses',
    removePath: '',
    multiDelete: false,
    accessName: 'order-partner-status',
    includes: '',
    sortingFields: [0],
    fields: [
      {
        label: 'Наименование',
        field: 'title',
        sortField: 'title',
        showInTable: true,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Статусы смет',
    name: 'estimates',
    path: 'estimate/statuses',
    removePath: '',
    multiDelete: true,
    accessName: 'estimate-status',
    includes: '',
    sortingFields: [0],
    fields: [
      {
        label: 'Уникальный псевдоним',
        field: 'slug',
        sortField: 'slug',
        showInTable: false,
        sort: 0,
        options: [],
      },
      {
        label: 'Наименование',
        field: 'title',
        sortField: 'title',
        showInTable: true,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Шоссе',
    name: 'highways',
    path: 'highways',
    removePath: '',
    multiDelete: true,
    accessName: 'highway',
    includes: 'region,params_schema',
    sortingFields: [0],
    fields: [
      {
        label: 'Наименование',
        field: 'name',
        sortField: 'name',
        showInTable: true,
        sort: 0,
        options: [],
      },
      {
        label: 'Регион',
        field: 'region',
        sortField: 'region_id',
        showInTable: true,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Статусы мероприятий',
    name: 'events-statuses',
    path: 'event-statuses',
    removePath: 'event-statuses',
    multiDelete: true,
    accessName: 'event-status',
    includes: 'params_schema',
    sortingFields: [0],
    fields: [
      {
        label: 'Уникальный псевдоним',
        field: 'slug',
        sortField: 'slug',
        showInTable: false,
        sort: 0,
        options: [],
      },
      {
        label: 'Наименование',
        field: 'name',
        sortField: 'name',
        showInTable: true,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Типы мероприятий',
    name: 'events-types',
    path: 'event-types',
    removePath: 'event-types',
    multiDelete: true,
    accessName: 'event-type',
    includes: 'params_schema',
    sortingFields: [0],
    fields: [
      {
        label: 'Уникальный псевдоним',
        field: 'slug',
        sortField: 'slug',
        showInTable: false,
        sort: 0,
        options: [],
      },
      {
        label: 'Наименование',
        field: 'name',
        sortField: 'name',
        showInTable: true,
        sort: 0,
        options: [],
      },
      {
        label: 'Продолжительность (минуты)',
        field: 'time_size',
        sortField: 'time_size',
        type: 'number',
        showInTable: true,
        sort: 0,
        options: [],
      },
      {
        label: 'Требует подтверждения',
        field: 'be_confirmed',
        sortField: 'be_confirmed',
        type: 'boolean',
        showInTable: true,
        sort: 0,
        options: [],
      },
      {
        label: 'Требует закрытия',
        field: 'be_closed',
        sortField: 'be_closed',
        type: 'boolean',
        showInTable: true,
        sort: 0,
        options: [],
      },
      {
        label: 'Учитывать другие события',
        field: 'consider_other',
        sortField: 'consider_other',
        type: 'boolean',
        showInTable: true,
        sort: 0,
        options: [],
      },
      {
        label: 'Многодневное мероприятие',
        field: 'multi_day',
        sortField: 'multi_day',
        type: 'boolean',
        showInTable: true,
        sort: 0,
        options: [],
      },
    ],
  },
  {
    title: 'Особенности мероприятий',
    name: 'events-feature',
    path: 'event-features',
    removePath: 'event-features',
    multiDelete: true,
    accessName: 'event-feature',
    includes: '',
    sortingFields: [0],
    fields: [
      {
        label: 'Уникальный псевдоним',
        field: 'slug',
        sortField: 'slug',
        showInTable: false,
        sort: 0,
        options: [],
      },
      {
        label: 'Наименование',
        field: 'name',
        sortField: 'name',
        showInTable: true,
        sort: 0,
        options: [],
      },
    ],
  },
]
